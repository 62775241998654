import { Switch, Route } from 'react-router-dom';
import './App.css';
import { useState } from 'react';
import { useEffect } from 'react';

// Import your components and containers
import NotFound from './containers/NotFound';
import Header from './containers/header';
import Footer from './containers/footer';
import Home from './containers/home';

const App = () => {
  
  return (
    <div className='theme_light_container responsiveHeight' style={{ minHeight: '100vh' }}>
      <Header />
        <Switch>
          <Route exact path="/" render={(props) => <Home />}  />
          <Route component={NotFound} />
        </Switch>
      <Footer />
    </div>
  );
};

export default App;
