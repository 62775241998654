import React, { useEffect } from "react";

const GoogleTranslate = () => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <>
      <div id="google_translate_element" className="googleTranslate"></div>
      <style>{`
            .googleTranslate .goog-te-gadget{
                color: #ffffff;
            }
            .googleTranslate .goog-te-gadget span a {
                color: #f4f4f4;
            }
            .goog-te-combo{
              padding: 8px;
              border-radius: 5px;
              color: #7f7f7f;
              border: 1px solid #969696;
              cursor: pointer;
              box-shadow:0px 6px 22px 6px rgb(186 186 186 / 21%);
            }
            .googleTranslate  span {
              display: none;
            }
        `}</style>
    </>
  );
};

export default GoogleTranslate;
