import React, { useState } from "react";
import "../../styles/section4.css";
import OwnDrawingBtn from "./OwnDrawingBtn";
import {
  FadeAnimate,
  FadeBottomAnimate,
} from "../../components/animateEffect/Fade";
import { RedirectLink } from "../../components/redirectLink/redirect";
// Reusable Card component
const Card = ({ title, description, imageSrc }) => {
  // Function to extract video ID from YouTube Shorts link
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };
  {
    !isVideoLoaded && <div className="loader">Loading...</div>;
  }
  const extractVideoIdFromShortsLink = (shortsLink) => {
    const match = shortsLink.match(/\/embed\/([^?]+)/);
    return match ? match[1] : null;
  };

  const videoId = extractVideoIdFromShortsLink(imageSrc);
  return (
    <div className="card">
      {/* <img
      alt={`Placeholder image for ${title}`}
      className="card-img-top"
      src={imageSrc}
    /> */}
      {/* <video
        width="100%"
        height="100%"
        preload={"auto"}
        autoPlay={true}
        loop
        // controls
        muted
        controlsList=""
        poster={Config.Icons.loading}
      >
        <source
          src={`https://www.youtube.com/embed/${videoId}`}
          type="video/mp4"
        />
        Sorry, your browser doesn't support videos.
      </video> */}
      <iframe
        // width="600"
        height="400"
        src={`https://www.youtube.com/embed/${videoId}?mute=1`}
        title="YouTube Video Player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        // onLoad={handleVideoLoad}
      />
      <h5 className="card-title">{title}</h5>
      <p className="card-text">{description}</p>
    </div>
  );
};

const Section4 = () => {
  // Sample data for cards
  const cardsData = [
    {
      title: "AR Drawing - Sketches",
      description:
        "Trace or Draw pictures on paper anything you want with the help of camera on your phone.",
      imageSrc: "https://www.youtube.com/embed/VBk6H-OEdH4?si=v87NDd-wmu6zakjR",
    },
    {
      title: "AR Drawing - Sketches",
      description:
        "Trace or Draw pictures on paper anything you want with the help of camera on your phone.",
      imageSrc: "https://www.youtube.com/embed/TBG9lPhnH14?si=5Wd_DERXgc_RmOCF",
    },
    {
      title: "AR Drawing - Sketches",
      description:
        "Trace or Draw pictures on paper anything you want with the help of camera on your phone.",
      imageSrc: "https://www.youtube.com/embed/BfdNuwcnEpY?si=mPh3FZtuxtKUKfpe",
    },
    {
      title: "AR Drawing - Sketches",
      description:
        "Trace or Draw pictures on paper anything you want with the help of camera on your phone.",
      imageSrc: "https://www.youtube.com/embed/PukOo6e0DUs?si=9t4kK0FkZIWEasKx",
    },
    {
      title: "AR Drawing - Sketches",
      description:
        "Trace or Draw pictures on paper anything you want with the help of camera on your phone.",
      imageSrc: "https://www.youtube.com/embed/ZcVXQpGLMQE?si=aA5vQo5Gu-rg0Ue4",
    },
    {
      title: "AR Drawing - Sketches",
      description:
        "Trace or Draw pictures on paper anything you want with the help of camera on your phone.",
      imageSrc: "https://www.youtube.com/embed/y1DU1txYoX4?si=s_AAlHY8IkltSo4p",
    },
    {
      title: "AR Drawing - Sketches",
      description:
        "Trace or Draw pictures on paper anything you want with the help of camera on your phone.",
      imageSrc: "https://www.youtube.com/embed/gUqDfYvwKN4?si=E3Tu0rMdNqCMPYUK",
    },
  ];

  return (
    <div className="section4Container">
      <div className="container sectionVerticalSpace">
        <div className="col-xl-6 text-center m-auto sectionTitleBS">
          <FadeAnimate>
            <h1 className="header-title">
              User Experience and Uploaded his works.
            </h1>
            <p className="flyer-description mb-0">
              AR Drawing fantastic design components and functionality make it
              simple and quick to create personalized ART Drawings.Seen Some
              Example
            </p>
          </FadeAnimate>
        </div>
        <div className="grid">
          {cardsData.map((card, index) => (
            <FadeBottomAnimate>
              <RedirectLink>
                <Card key={index} {...card} />
              </RedirectLink>
            </FadeBottomAnimate>
          ))}
        </div>
        <OwnDrawingBtn />
      </div>
    </div>
  );
};

export default Section4;
