import React from "react";
import "../../styles/loader.css";
const DefaultLoader = () => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-center vh-50">
        <div className="position-relative">
          <div className="loaderCircle"></div>
          <div className="loaderInnerCircle animate-spin"></div>
        </div>
      </div>
    </>
  );
};

export default DefaultLoader;
