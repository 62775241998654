import React from "react";
import { Fade } from "react-reveal";

export const FadeAnimate = ({ children }) => {
  return <Fade>{children}</Fade>;
};

export const FadeLeftAnimate = ({ children }) => {
  return <Fade left>{children}</Fade>;
};

export const FadeRightAnimate = ({ children }) => {
  return <Fade right>{children}</Fade>;
};

export const FadeBottomAnimate = ({ children }) => {
  return <Fade bottom>{children}</Fade>;
};
