import DOMPurify from "dompurify";

export const TextToHtmlComponent = ({ text }) => {
    const sanitizedHtml = DOMPurify.sanitize(text);
  
    const createMarkup = () => {
      return { __html: sanitizedHtml };
    };
  
    return <div dangerouslySetInnerHTML={createMarkup()} />;
  };
  