// ReviewCard.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Image from "../../components/Image";

const ReviewCard = ({ username, rating, review, imageUrl }) => (
  <div className="mb-2 pr-3 pl-1 pt-1">
    <div className="review-card h-100">
      <div className="d-flex pb-2">
        <Image alt="User profile" className="user_profile_img" src={imageUrl} />
        <div className="pl-3">
          <h5>{username}</h5>
          <p className="rating m-0">
            {[...Array(rating)].map((_, index) => (
              <FontAwesomeIcon key={index} icon={faStar} className="rate-star" />
            ))}
          </p>
        </div>
      </div>
      <p className="revierName mb-1">Seamless experience</p>
      <p className="mb-0 truncate-4-text">{review}</p>
    </div>
  </div>
);

export default ReviewCard;
