import React from "react";
import "../../styles/section2.css";
import OwnDrawingBtn from "./OwnDrawingBtn";
import * as Config from "../../lib/config";
import {
  FadeAnimate,
  FadeBottomAnimate,
} from "../../components/animateEffect/Fade";
import CustomSlider from "../../components/reactSlider/slider";
import { RedirectLink } from "../../components/redirectLink/redirect";
import Image from "../../components/Image";

const flyerData = [
  {
    title: "AR Drawing Anime",
    description:
      "Step into a world where Anime comes to life with Draw Anime Sketch: AR Draw!",
    imageUrl: Config.sketch.AnimeSketch,
  },
  {
    title: "AR Drawing Car",
    description:
      "Very Simple App. Just Draw and Draw more. Sprinkle a little Creativity.",
    imageUrl: Config.sketch.carSketch,
  },
  {
    title: "Draw Easy - Trace to Sketch",
    description:
      "You Can Leatn Drawing or Tracing.You can draw anything you want on any surface.",
    imageUrl: Config.sketch.CuteSketch,
  },
  {
    title: "Drawing and Painting",
    description:
      "Use your phone to trace and sketch! Create Amazing Sketch & Paint.",
    imageUrl: Config.sketch.DrawingPaintingSketch,
  },
  {
    title: "Drawing apps Free sketch",
    description:
      "AR Drawing - Sketches a Powerful tool for Drawing Sketch. Locate the phone on a steady tripod or object. Open AR Drawing - Sketches.Create your stunning masterpieces!",
    imageUrl: Config.sketch.PeopleSketch,
  },
];

const Settings = {
  dots: false,
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1540,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
};
const Section2 = () => {
  return (
    <div className="section2Container" id="Templates">
      <div className="container sectionVerticalSpace">
        <div className="col-xl-6 text-center m-auto sectionTitleBS">
          <FadeAnimate>
            <h1 className="header-title">Create Sketches using AR Drawing </h1>
            <p className="flyer-description mb-0">
              Spending hours to design visually appealing AR Drawings? Don't
              worry, we offer a user-friendly digital AR Drawing maker app with
              eye-catching AR Drawing templates to customize and create a AR
              Drawing in a minute.
            </p>
          </FadeAnimate>
        </div>
        <div className="slick_Arrow sketchSlider">
          <CustomSlider settings={Settings}>
            {flyerData.map((flyer, index) => (
              <FadeBottomAnimate>
                <RedirectLink>
                  <div key={index} className="col-12 mb-4 mb-md-0">
                    <div className="card">
                      <div className="cardImageSection">
                        <Image
                          alt={`Placeholder image for ${flyer.title}`}
                          className="card-img-top"
                          src={flyer.imageUrl}
                        />
                      </div>
                      <h5 className="card-title">{flyer.title}</h5>
                      <p className="card-text">{flyer.description}</p>
                    </div>
                  </div>
                </RedirectLink>
              </FadeBottomAnimate>
            ))}
          </CustomSlider>
        </div>
        <OwnDrawingBtn themeColor={true} />
      </div>
    </div>
  );
};

export default Section2;
