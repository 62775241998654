import React from "react";
import "../../styles/section3.css";
import OwnDrawingBtn from "./OwnDrawingBtn";
import {
  FadeAnimate,
  FadeLeftAnimate,
  FadeRightAnimate,
} from "../../components/animateEffect/Fade";
import { TextToHtmlComponent } from "../../lib/html-parser";
import * as Config from "../../lib/config";
import Image from "../../components/Image";

const Section3 = () => {
  const steps = [
    {
      title: "Customize Design",
      description: `
        <div>You just need to upload a picture or line drawing that you want to trace. According to the view adjust the image and keep your phone above the page using a tripod or a cup.     
        <ul>
          <li>Upload good quality image.</li>
          <li>Make image transparent or contrast.</li>
          <li>Adjust image & create traceable.</li>
          <li>Place your phone on Tripod or cup above page.</li>
          <li>Quick draw and create an art.</li>
        </ul>`,
      imageUrl: Config.homeHorizontalBanner.banner1,
    },
    {
      title: "Choose Your Favorite Sketch",
      description: `Having AR Drawing App is like always having a sheet of tracing paper with you! Choose any image you'd like to draw, take a photo, or choose from hundreds of images supplied in the app; then look at your screen while you trace the image on paper! an you can draw it!`,
      imageUrl: Config.homeHorizontalBanner.banner2,
    },

    {
      title: "Advanced Features",
      description: `
        <ul>
          <li>You can record video while drawing and creating your art.</li>
          <li>You can click the image of your art directly from the app itself.</li>
          <li>You can record video from the app itself.</li>
          <li>Time lapse video while creating art from the app itself.</li>
          <li>On/off the flash light from the app while recording video and drawing.</li>
          <li>Browse Image feature allows you to browse the images and import it in the app from search engine.</li>
        </ul>
        `,
      imageUrl: Config.homeHorizontalBanner.banner3,
    },
  ];

  return (
    <div className="section3Container">
      <div className="container sectionVerticalSpace">
        <div className="col-xl-6 text-center m-auto sectionTitleBS">
          <FadeAnimate>
            <h1 className="header-title">How to Use AR Drawing App</h1>
            <p className="flyer-description mb-0">
              Just trace a projected picture on paper and color it! Learn how to
              draw in 7 days!
            </p>
          </FadeAnimate>
        </div>
        {steps.map((step, index) => (
          <div key={index} className="row">
            <div
              className={`col-12 col-md-6 ${
                index % 2 === 0
                  ? "pl-md-0 text-center text-md-right"
                  : "pr-md-0 order-2 order-md-1 text-center text-md-right d-flex justify-content-center flex-column"
              }`}
            >
              {index % 2 === 0 ? (
                <>
                  <FadeRightAnimate>
                    <div className="py-md-4 pl-md-4">
                      <Image
                        alt={`Step ${index + 1}`}
                        className="card-img-top rounded-4"
                        src={step.imageUrl}
                      />
                    </div>
                  </FadeRightAnimate>
                </>
              ) : (
                <FadeLeftAnimate right={true}>
                  <h3 className="sectionTitle">{step.title}</h3>
                  <p className="sectionDescription">
                    <TextToHtmlComponent text={step.description} />
                  </p>
                </FadeLeftAnimate>
              )}
            </div>
            <div
              className={`col-12 col-md-6 ${
                index % 2 === 0 ? " pl-md-0" : "order-1 order-md-2 pr-md-0"
              } d-flex justify-content-center flex-column text-center text-md-left`}
            >
              {index % 2 === 0 ? (
                <FadeLeftAnimate>
                  <h3 className="sectionTitle">{step.title}</h3>
                  <p className="sectionDescription">
                    <TextToHtmlComponent text={step.description} />
                  </p>
                </FadeLeftAnimate>
              ) : (
                <FadeRightAnimate>
                  <div className="py-md-4 pr-md-4">
                    <Image
                      alt={`Step ${index + 1}`}
                      className="card-img-top  rounded-4"
                      src={step.imageUrl}
                    />
                  </div>
                </FadeRightAnimate>
              )}
            </div>
          </div>
        ))}
        <OwnDrawingBtn />
      </div>
    </div>
  );
};

export default Section3;
