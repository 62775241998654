import playstore from "../static/images/playstore.png";
import appstore from "../static/images/appstore.png";
import websiteLogo from "../static/images/websiteLogo.png";
// import chevronRight from "../static/icons/chevron-right.svg";
// import externalLink from "../static/icons/externalLink.svg";
import rightIcon from "../static/icons/rightIcon.png";
import Google_Play_icon from "../static/icons/Google_Play_icon.svg";
import App_Store_Icon from "../static/icons/App_Store_Icon.svg";
import rightIconSvg from "../static/icons/rightIcon.svg";
import rightIconblueSvg from "../static/icons/rightIcon-blue.svg";
// import menuLines from "../static/icons/menuLines.svg";
import appIcon from "../static/images/appIcon.png";
import QRCOde from "../static/images/QRCOde.png";
import loading from "../static/icons/loading.gif";

import FacebookLogo from "../static/icons/FacebookLogo.svg";
import InstagramLogo from "../static/icons/InstagramLogo.svg";
import twitterIcon from "../static/icons/TwitterLogo.svg";
import LinkedInLogo from "../static/icons/LinkedInLogo.svg";
import PinterestLogo from "../static/icons/PinterestLogo.svg";
import youtubeIcon from "../static/icons/youtubeIcon.svg";

import profile1 from "../static/images/personProfile/profile1.jpg";
import profile2 from "../static/images/personProfile/profile2.jpg";
import profile3 from "../static/images/personProfile/profile3.jpg";
import profile4 from "../static/images/personProfile/profile4.jpg";
import profile5 from "../static/images/personProfile/profile5.jpg";

import AnimeSketch from "../static/images/sketch/Anime.jpg";
import CuteSketch from "../static/images/sketch/Cute.jpg";
import DrawingPaintingSketch from "../static/images/sketch/DrawingPainting.jpg";
import PeopleSketch from "../static/images/sketch/People.jpg";
import carSketch from "../static/images/sketch/car.jpg";

import banner1 from "../static/images/banners/1.png";
import banner2 from "../static/images/banners/2.png";
import banner3 from "../static/images/banners/13.png";

import sliderBanner1 from "../static/images/sliderBanner/slider1.jpg";
import sliderBanner2 from "../static/images/sliderBanner/slider2.jpg";
import sliderBanner3 from "../static/images/sliderBanner/slider3.jpg";
import sliderBanner4 from "../static/images/sliderBanner/slider4.jpg";
import sliderBanner5 from "../static/images/sliderBanner/slider5.jpg";

export const sketch = {
  AnimeSketch,
  CuteSketch,
  DrawingPaintingSketch,
  PeopleSketch,
  carSketch,
};

export const images = {
  // externalLink,
  playstore,
  appstore,
  appIcon,
  QRCOde,
  websiteLogo,
};

export const Icons = {
  rightIcon,
  rightIconSvg,
  rightIconblueSvg,
  // chevronRight,
  // menuLines,
  loading,
  App_Store_Icon,
  Google_Play_icon
};

export const socialIcons = {
  FacebookLogo,
  InstagramLogo,
  twitterIcon,
  LinkedInLogo,
  PinterestLogo,
  youtubeIcon,
};

export const personProfile = {
  profile1,
  profile2,
  profile3,
  profile4,
  profile5,
};

export const homeHorizontalBanner = {
  banner1,
  banner2,
  banner3,
};

export const sliderBanners = {
  sliderBanner1,
  sliderBanner2,
  sliderBanner3,
  sliderBanner4,
  sliderBanner5
};

// src="https://placehold.co/300x400"

export const THEME_COLOR = "#635ee7";
export const THEME_BG_COLOR = "#1a1b1f";
export const IphoneLink = "https://apps.apple.com/us/app/ar-drawing-anime-drawing/id6452237122";