import React, { useEffect, useState } from "react";
import BannerSection from "./homeSection/bannerSection";
import Section1 from "./homeSection/section1";
import "../styles/button.css";
import Section2 from "./homeSection/section2";
import Section3 from "./homeSection/section3";
import Section4 from "./homeSection/section4";
import Section5 from "./homeSection/section5";
import Faqs from "./homeSection/faqs";
import DefaultLoader from "../components/loader";

const Home = ({ isiPhone }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    setTimeout(() => {
      // Set isLoading to false when the data is loaded or component is ready
      setIsLoading(false);
    }, 1000); // Simulating a 2-second loading time
  }, []);
  return (
    <>
      <section className="position-relative">
        {isLoading ? (
          <div style={{ height:"100vh" }} className="d-flex align-items-center justify-content-center">
            <DefaultLoader />
          </div>
        ) : (
          <>
            <BannerSection isiPhone={isiPhone} />
            <Section2 isiPhone={isiPhone} />
            <Section1 isiPhone={isiPhone} />
            <Section3 isiPhone={isiPhone} />
            <Section4 isiPhone={isiPhone} />
            <Section5 isiPhone={isiPhone} />
            <Faqs isiPhone={isiPhone} />
          </>
        )}
      </section>
    </>
  );
};

export default Home;
