import React from "react";

const Image = (props) => {
  const { src, alt, name, className = '', style, height, width } = props;

  // Use the split method to divide the path into an array based on the '/'
  const pathArray = src.split("/");

  // Get the last element of the array, which represents the file name
  const lastPathName = pathArray[pathArray.length - 1];
  return (
    <>
      <img
        src={src}
        alt={alt || lastPathName}
        name={name || lastPathName}
        className={`${className} img-fluid`}
        style={style}
        width={width}
        height={height}
      />
    </>
  );
};

export default Image;
