import React from "react";
import "../../styles/section1.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faFileAlt,
  faFont,
  faPaintBrush,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";
import OwnDrawingBtn from "./OwnDrawingBtn";
import { FadeAnimate, FadeBottomAnimate } from "../../components/animateEffect/Fade";

const featuresData = [
  {
    icon: faFileAlt,
    title: "Easy-to-Use User Interface",
    description:
      "Comfortable to navigate and gives you the Sketche you need when you need them.",
  },
  {
    icon: faPaintBrush,
    title: "Customizable Photos or images",
    description:
      "You just need to upload a picture or line drawing that you want to trace. According to the view adjust the image and keep your phone above the page using a tripod or a cup.",
  },
  {
    icon: faFont,
    title: "Write Your Text or Name",
    description:
      "You Can Draw Your Text or pictures on paper anything you want with the help of camera on your phone.",
  },
  {
    icon: faDownload,
    title: "One-Click Download",
    description:
      "Download app for Android or iPhone Device. it's app are Free, Quick draw and create an art.",
  },
  {
    icon: faShareAlt,
    title: "Share / Save video or images",
    description: "You can share or save a beautiful art video.",
  },
];

const Section1 = () => {
  return (
    <div className="bannerContainer" id="Features">
      <div className="container sectionVerticalSpace">
        <div className="row justify-content-center sectionTitleBS">
          <div className="col-lg-9 text-center">
          <FadeAnimate>
            <h1 className="header-title">A Quick AR Drawing Sketches App</h1>
            <h2 className="header-subtitle">For Your Business or Events</h2>
            <p className="flyer-description mb-0">
              No design experience is needed – our AR Drawing creator is
              user-friendly, making it accessible for anyone to design
              professional AR Drawings in minutes.
            </p>
          </FadeAnimate>
          </div>
        </div>
        <div className="row feature-row">
          {featuresData.map((feature, index) => (
            <FadeBottomAnimate>
              <div key={index} className="col-md-4 feature-col mb-4 mb-md-2">
                <div className="feature-item text-center d-flex flex-column">
                  <FontAwesomeIcon
                    icon={feature.icon}
                    className="feature-icon"
                  />
                  <h5>{feature.title}</h5>
                  <p>{feature.description}</p>
                </div>
              </div>
            </FadeBottomAnimate>
          ))}
        </div>
        <OwnDrawingBtn />
      </div>
    </div>
  );
};

export default Section1;
