import React from "react";
import "../../styles/bannerSection.css";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Config from "../../lib/config";
import Image from "../../components/Image";
import { FadeLeftAnimate } from "../../components/animateEffect/Fade";
import { PulseAnimate } from "../../components/animateEffect/Pulse";
import CustomSlider from "../../components/reactSlider/slider";
import { RedirectLink } from "../../components/redirectLink/redirect";

const Settings = {
  dots: true,
  infinite: true,
  arrows: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1540,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
};

const BannerSection = () => {
  const sliderBanner = [
    Config.sliderBanners.sliderBanner1,
    Config.sliderBanners.sliderBanner2,
    Config.sliderBanners.sliderBanner3,
    Config.sliderBanners.sliderBanner4,
    Config.sliderBanners.sliderBanner5,
  ];
  return (
    <div className="section4Container">
      <div className="container sectionVerticalSpace">
        <div className="row">
          <div className="col-lg-8">
            <FadeLeftAnimate>
              <h1>AR Drawing</h1>
              <p>
                Make a Drawing using AR Drawing creator app. 20,000+ AR Drawing
                templates to customize. Quick & Easy to Use. No AR Drawing
                design skills are required. Save your time & money.
              </p>
            </FadeLeftAnimate>
            <div className="user-rating mb-2 d-flex">
              {/* <!-- Repeat for each user image --> */}
              <div className="pr-3">
                <Image
                  src={Config.personProfile.profile1}
                  alt="User profile image"
                  width="40"
                  height="40"
                  className="personProfiles"
                />
                <Image
                  src={Config.personProfile.profile2}
                  alt="User profile image"
                  width="40"
                  height="40"
                  className="personProfiles"
                />
                <Image
                  src={Config.personProfile.profile3}
                  alt="User profile image"
                  width="40"
                  height="40"
                  className="personProfiles"
                />
                <Image
                  src={Config.personProfile.profile4}
                  alt="User profile image"
                  width="40"
                  height="40"
                  className="personProfiles"
                />
                <Image
                  src={Config.personProfile.profile5}
                  alt="User profile image"
                  width="40"
                  height="40"
                  className="personProfiles"
                />
              </div>
              {/* <!-- Star icons --> */}
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faStar} className="fa-star" />
                <FontAwesomeIcon icon={faStar} className="fa-star" />
                <FontAwesomeIcon icon={faStar} className="fa-star" />
                <FontAwesomeIcon icon={faStar} className="fa-star" />
                <FontAwesomeIcon icon={faStar} className="fa-star" />
              </div>
            </div>
            <p>
              Over 20M+ users are designing Drawing using the AR Drawing
              Sketches app.
            </p>
            <div className="download-buttons mb-4">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.iApp.ar.drawing.traceanything"
              >
                <Image
                  src={Config.images.playstore}
                  alt="Download on Google Play"
                  width="150"
                  height="50"
                  className="shareIcons"
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={Config.IphoneLink}
              >
                <Image
                  src={Config.images.appstore}
                  alt="Download on the App Store"
                  width="150"
                  height="50"
                  className="shareIcons"
                />
              </a>

              <a target="_blank" rel="noreferrer" href="">
                <Image
                  src={Config.images.websiteLogo}
                  alt="Available as Website"
                  width="150"
                  height="50"
                  className="shareIcons"
                />
              </a>
            </div>
            <div className="col-12 col-md-7 p-0 ">
              <div className="qr-code row w-100 col-12 m-0">
                <div className="col-3 p-0 d-flex align-items-center">
                  <PulseAnimate>
                    <Image
                      src={Config.images.QRCOde}
                      alt="Available as Website"
                      width="150"
                      height="50"
                      className="qrCOdeImg"
                    />
                  </PulseAnimate>
                </div>
                <div className="col-9">
                  <p className="downloadTitle">Download or Fall Behind!</p>
                  <p className="downloadSubTitle">
                    Unlock the app's magic with a simple scan. Dive into
                    exclusive content. Download now and dive in!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 pt-4 pt-md-0">
            {/* <div className="template-counter">20,000+ Templates</div> */}
            <div className="">
              <RedirectLink>
                <div className="flyer-example slick_Arrow sketchSlider ">
                  <CustomSlider settings={Settings}>
                    {sliderBanner.map((banner, index) => (
                      <>
                        <Image
                          src={banner}
                          alt="Example AR Drawing image with text 'BAKE SALE - PIES, CAKES, COOKIES & MORE' and a discount offer /"
                          width="300"
                          height="400"
                          className="rounded-2"
                        />
                      </>
                    ))}
                  </CustomSlider>
                </div>
              </RedirectLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerSection;
