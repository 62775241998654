import React from "react";
import * as Config from "../../lib/config";
import Image from "../../components/Image";
import { FadeAnimate } from "../../components/animateEffect/Fade";
import { RedirectLink } from "../../components/redirectLink/redirect";
const OwnDrawingBtn = (props) => {
  const { themeColor } = props;
  return (
    <>
      <FadeAnimate>
        <RedirectLink>
          <div className="text-center mt-4">
            <button className="btn btn-primary commandButton">
              Create Sketches
              <span className="pl-2 commanArrow">
                <Image
                  src={themeColor ? Config.Icons.rightIconblueSvg : Config.Icons.rightIconSvg}
                  alt="img-rightIcon"
                  name="rightIcon"
                  className="img-fuild"
                />
              </span>
            </button>
          </div>
        </RedirectLink>
      </FadeAnimate>
    </>
  );
};

export default OwnDrawingBtn;
