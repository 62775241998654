import GoogleTranslate from "../components/googleTranslate";
import "../styles/header.css";
import * as Config from "../lib/config";
import Image from "../components/Image";
import { Navbar, Nav } from "react-bootstrap";
import { useState } from "react";
// import { Link } from "react-scroll";
import { Link as ScrollLink } from "react-scroll";
import { RedirectLink } from "../components/redirectLink/redirect";
const Header = () => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <Navbar expand="lg" expanded={expanded}>
        <div className="container headerContainer py-md-0 py-1">
          <Navbar.Brand href="#home">
            <a className="navbar-brand d-flex align-items-center" href="#">
              <Image
                alt="AR Drawing logo placeholder image"
                height="30"
                src={Config.images.appIcon}
                width="70"
                className="rounded-4"
              />
              <div className="brandName pl-2">
                <div className="brandCharName m-0">AR</div> Drawing
              </div>
            </a>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(!expanded)}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <div
                className="collapse navbar-collapse show"
                id="navbarNavAltMarkup"
              >
                <div className="navbar-nav m-auto">
                  <ScrollLink
                    className="nav-link"
                    to="Features"
                    smooth={true}
                    duration={100}
                  >
                    Features
                  </ScrollLink>
                  <ScrollLink
                    className="nav-link"
                    to="Templates"
                    smooth={true}
                    duration={100}
                  >
                    Templates
                  </ScrollLink>
                  <ScrollLink
                    className="nav-link"
                    to="Reviews"
                    smooth={true}
                    duration={100}
                  >
                    Reviews
                  </ScrollLink>
                  {/* <ScrollLink
                    className="nav-link"
                    to="#"
                    smooth={true}
                    duration={100}
                  >
                    Learn
                  </ScrollLink> */}
                  {/* <ScrollLink
                    className="nav-link"
                    to="#"
                    smooth={true}
                    duration={100}
                  >
                    Tools
                  </ScrollLink> */}
                  <div className="mr-2" style={{ height: "42px" }}>
                    <GoogleTranslate />
                  </div>
                </div>
              </div>
              <RedirectLink>
                <button
                  className="btn-get-started btn d-none d-lg-block"
                  type="button"
                >
                  Create Sketches
                  <span className="pl-2 commanArrow">
                    <Image
                      src={Config.Icons.rightIconSvg}
                      alt="img-rightIcon"
                      name="rightIcon"
                      className="img-fuild"
                    />
                  </span>
                </button>
              </RedirectLink>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  );
};

export default Header;
